import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

export const initializeGA = () => {
  ReactGA.initialize("UA-177866127-1", { debug: process.env.NODE_ENV === "development" });
};

export const logPageView = history => {
  history.listen(location => {
    const getPageNameFull = ({ href, search }) => href + search;
    const page = getPageNameFull(location.pathname ? location : window.location);
    ReactGA.set({ page: page });
    ReactGA.pageview(page);
    //console.log(`Page View logged for: ${page}`);
  });
};

export const withTracker = WrappedComponent => {
  return props => {
    const history = useHistory();

    useEffect(() => {
      logPageView(history);
    }, [history]);

    return <WrappedComponent {...props} />;
  };
};
