import createAxiosInstance from "./axiosOptimized";

const invoiceBaseURL = process.env.REACT_APP_INVOICE_API_URL;
const { getRequest, postRequest, postFileRequest, delRequest } = createAxiosInstance(invoiceBaseURL);

//client invoicing
export const getInvoiceActionItems = isClient =>
  isClient ? getRequest(`clientinvoice/action-items`) : getRequest(`invoice/action-items`);
export const getClientsAndNotifications = () => getRequest(`invoice/clients-notifications`);
export const getInvoiceNotifications = isClient =>
  isClient ? getRequest(`clientinvoice/notification`) : getRequest(`invoice/notification`);

export const getClientInvoiceById = ({ clientId, invoiceIds, filter, isClient }) =>
  isClient
    ? postRequest(`clientinvoice/getResByInvoices`, { invoiceIds, filter })
    : postRequest(`invoice/getResByInvoices`, { invoiceIds, filter, clientId });

export const getClientInvoices = ({ isClient, clientId, filter }) =>
  isClient
    ? getRequest(`clientinvoice/getInvoices?filter=${filter}`)
    : getRequest(`invoice/client/${clientId}?filter=${filter}`);

export const fetchPaymentsForReservation = ({ clientId, reservationInvoiceId, isClient }) =>
  isClient
    ? getRequest(`clientinvoice/payments-res/${reservationInvoiceId}`)
    : getRequest(`invoice/payments-client-res/${clientId}/${reservationInvoiceId}`);
export const getAuditLogForInvoice = ({ reservationInvoiceId, isClient }) =>
  isClient
    ? getRequest(`clientinvoice/client-audit-trail?resInvoiceId=${reservationInvoiceId}`)
    : getRequest(`invoice/invoice-audit-trail?resInvoiceId=${reservationInvoiceId}`);
export const getAccountSummary = clientId =>
  clientId
    ? getRequest(`invoice/clientDueSummary/${clientId}`)
    : getRequest(`clientinvoice/getDueSummary`);

export const dismissInvoiceNotifications = ({ isClient, notificationId }) =>
  isClient
    ? postRequest(`clientinvoice/notification/${notificationId}/dismiss`)
    : postRequest(`invoice/notification/${notificationId}/dismiss`);
export const adminApproveDeclineAction = reqBody =>
  postRequest("invoice/admin-approve-decline", reqBody);

export const getCreditUsageInfo = ({ clientId, htConfirmationCode, isClient, creditId }) =>
  isClient
    ? getRequest(
        `clientinvoice/credit-usage?htConfirmationCode=${htConfirmationCode}&creditId=${creditId}`
      )
    : getRequest(
        `invoice/${clientId}/credit-usage?htConfirmationCode=${htConfirmationCode}&creditId=${creditId}`
      );
export const getCreditsForType = ({ isClient, cardType, clientId, currency }) =>
  postRequest(
    isClient
      ? `clientinvoice/credits?filter=${cardType}&currency=${currency}`
      : `invoice/credits?clientId=${clientId}&filter=${cardType}&currency=${currency}`
  );

export const searchCredits = ({ creditsRequest, isClient }) =>
  postRequest(isClient ? "clientinvoice/credits/" : "invoice/credits/", creditsRequest);

export const invoiceDoNotAllocateFunds = reqBody => postRequest("invoice/payment", reqBody);
export const getPaymentDetails = ({ isClient, clientId }) =>
  isClient
    ? getRequest(`clientinvoice/payments`)
    : getRequest(`invoice/payments?clientId=${clientId || ""}`);

export const addInvoiceSchedulingDetails = reqBody =>
  postRequest(`invoice/invoice-details`, reqBody);
export const editInvoiceSchedulingDetails = reqBody =>
  postRequest(`invoice/invoice-details/update`, reqBody);

export const getInvoiceSchedulingDetails = () => getRequest("invoice/invoice-details");
export const getAllClientsAgingSummary = () => getRequest("invoice/client-aging-summary");
export const getClientSchedules = clientId =>
  getRequest("invoice/client-payment-schedules/" + clientId);
export const saveAndSubmitLaterInvoice = reqBody =>
  postRequest("clientinvoice/client-save", reqBody);
export const submitAndPayInvoice = reqBody => postRequest("clientinvoice/client-save-pay", reqBody);
export const getCreditsTotals = _clientId => getRequest("clientinvoice/credits/total");
export const getClientCreditsTotals = clientId =>
  getRequest("invoice/credits/client/" + clientId + "/total");

export const getClientCredits = clientId => getRequest("invoice/credits/client/" + clientId);

export const downloadInvoiceByClient = (invoiceIds, filter) =>
  postFileRequest("clientinvoice/download", { invoiceIds, filter });

export const downloadInvoice = (invoiceIds, filter, clientId) =>
  postFileRequest("invoice/download", { invoiceIds, filter, clientId });

export const getPaymentsForClientAndInvoice = ({ clientId, invoiceId }) =>
  clientId
    ? getRequest(`invoice/payments/client/${clientId}/invoice/${invoiceId}`)
    : getRequest("clientinvoice/payments/invoice/" + invoiceId);

export const getClientCreditTotals = reqBody =>
  postRequest("clientinvoice/credit-schedules", reqBody);

export const getClientCreditTotalsByAdmin = reqBody =>
  postRequest("invoice/credit-schedules", reqBody);

export const getInvoiceSummary = ({ clientId, invoiceIds, isClient, filter }) =>
  isClient
    ? postRequest(`clientinvoice/invoice-summary`, { invoiceIds, filter })
    : postRequest(`invoice/invoice-summary`, { invoiceIds, clientId, filter });

export const invoiceAllocateFunds = reqBody => postRequest("invoice/allocate-folios", reqBody);

export const getAllClientsExcludeScheduled = () => getRequest(`invoice/client-exclude-scheduled`);

// moved but not implemented in BE
export const downloadSupplierInvoice = fileName =>
  postFileRequest("invoice/downloadSupplierInvoice?fileName=" + fileName);

export const processSupplierInvoice = (file, { propertyId, supplierInvoiceIdType, captureInvoiceDate }) =>
  postRequest(
    `invoice/processSupplierInvoice?propertyId=${propertyId}&supplierInvoiceIdType=${supplierInvoiceIdType}&captureInvoiceDate=${captureInvoiceDate}`,
    file
  );

// Pending items
// To do. Remove this after pending items are removed from the UI
export const getClientOutboundRateXML = payload =>
  postRequest(`clientinvoice/intg_audit_ari`, payload);

export const saveAdminInvoiceChanges = reqBody =>
  postRequest("invoice/admin-save-changes", reqBody);

export const getInvoiceIdByHTConfCode = ({ isClient, htConfCode }) =>
  isClient ? getRequest(`clientinvoice/search/${htConfCode}`) : getRequest(`invoice/search/${htConfCode}`);

export const getAutoAllocatedReservation = () =>
  getRequest("clientinvoice/get-auto-allocated-reservation");
export const acceptAutoAllocatedReservation = () =>
  getRequest("clientinvoice/auto-allocation-accept");
export const allocateForSelectedReservations = payload =>
  postRequest("clientinvoice/client-allocation-finalize", payload);
export const rejectAutoAllocatedReservation = () =>
  getRequest("clientinvoice/auto-allocation-decline");

export const uploadInvoiceByClient = (file, invoiceId) =>
  postRequest("clientinvoice/uploadInvoice?invoiceId=" + invoiceId, file);
export const uploadInvoice = (file, invoiceId, clientId) =>
    postRequest(`invoice/uploadInvoice?invoiceId=${invoiceId}&clientId=${clientId}`, file);
export const fetchAutoAllocateFundsDetails = (payload, clientId) =>
  postRequest(`invoice/client/auto-allocate-funds/${clientId}`, payload);
export const finalizeAutoAllocateFundsDetails = (payload, clientId) =>
  postRequest(`invoice/client/auto-allocate-funds/${clientId}/finalize`, payload);

export const uploadSupplierInvoice = (file, supplierInvoiceRequest) =>
  postRequest(
    "invoice/uploadSupplierInvoice?startingRow=" +
      supplierInvoiceRequest.startingRow +
      "&crsColumn=" +
      supplierInvoiceRequest.crsColumn +
      "&totalAmountColumn=" +
      supplierInvoiceRequest.totalAmountColumn +
      "&invoiceDateColumn=" +
      supplierInvoiceRequest.invoiceDateColumn,
    file
  );

export const uploadInvoicePayloadToS3 = (actionType, reqBody) =>
  postRequest(`upload/invoice-payload?actionType=${actionType}`, reqBody);

export const deletePayment = paymentId => delRequest(`invoice/payments/${paymentId}`);
