import { combineReducers } from "redux";
import login from "./login";
import home from "./home";
import invoice from "./invoice";
import usersCompaniesManagement from "./usersCompaniesManagement";
import cancelReservation from "./cancelReservation";
import dayRateAvailability from "./dayRateAvailability";
import rateplanManagement from "./rateplanManagement";
import globalRatePlanManagement from "./globalRatePlanManagement";

export default combineReducers({
  login,
  home,
  invoice,
  usersCompaniesManagement,
  cancelReservation,
  dayRateAvailability,
  rateplanManagement,
  globalRatePlanManagement
});
