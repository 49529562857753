import {
  getFormattedDateInEDTWithFormat,
  getFormattedTime,
  getRoundedToTwoDecimals
} from "../../constants";
import { isASuperUserLoggedIn } from "../../utils/secure-xs/secureSS";
import { clientIntegrationTypes } from "../ClientManagement/constants";

export function downloadXML(xml, title) {
  if (typeof xml === "object") xml = JSON.stringify(xml);
  const blob = new Blob([new TextEncoder().encode(xml)], {
    type: "text/plain"
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  const fileName = title || "xml.xml";
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function downloadJSON(json, title) {
  const blob = new Blob([new TextEncoder().encode(json)], {
    type: "application/json"
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style = "display: none";
  a.href = url;
  const fileName = title || "json.json";
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function getElement(name, rootElement) {
  if (!rootElement) throw new Error("Null root element");
  if (rootElement.name === name) return rootElement;

  // Base case
  const { elements } = rootElement;
  if (!elements) return null;

  // Breadth First Search
  for (let i = 0; i < elements.length; i++) {
    const childElement = elements[i];
    if (childElement.name === name) return childElement;
  }

  // Check child elements recursively
  for (let j = 0; j < elements.length; j++) {
    const childElement = elements[j];
    const match = getElement(name, childElement);
    if (match) {
      return match;
    }
  }
}

export function processReservationReportForRendering(reports, isMultiService) {
  if (!isMultiService) {
    let newReports = [];
    reports.forEach(element => {
      //nullify the room details after creating a reservation for each room
      const { roomDetails } = element;
      if (roomDetails && roomDetails.length) {
        const rooms = roomDetails.map(room => ({
          ...element,
          ...room,
          roomDetails: null,
          reservationConfirmationNumber: element.confirmationNumber
        }));
        newReports = newReports.concat(rooms);
      } else {
        newReports.push({
          ...element,
          roomDetails: null,
          reservationConfirmationNumber: element.confirmationNumber
        });
      }
    });
    reports = newReports;
  }

  return reports.map(prepareReportToSupportMultiService);
}
function prepareReportToSupportMultiService(reservation, rowIndex) {
  const isSuperUser = isASuperUserLoggedIn();
  const id = "parent-" + rowIndex;
  if (isSuperUser) {
    if (reservation.serviceDetails) {
      reservation.children = reservation.serviceDetails.map((ser, i) => {
        let children;
        if (ser.roomDetails) {
          children = ser.roomDetails.map((roo, childIndex) => ({
            ...ser,
            ...roo,
            parentId: id + "-ser-" + i,
            index: rowIndex + 1 + "." + (i + 1) + "." + (childIndex + 1),
            type: "room",
            id: id + "-ser-" + i + "-roo-" + childIndex,
            children: null
          }));
        }
        return {
          ...ser,
          parentId: id,
          id: id + "-ser-" + i,
          type: "service",
          index: rowIndex + 1 + "." + (i + 1),
          children
        };
      });
    } else if (reservation.roomDetails && reservation.roomDetails.length > 1) {
      reservation.children = reservation.roomDetails.map((roo, i) => ({
        ...reservation,
        ...roo,
        parentId: id,
        index: rowIndex + 1 + "." + (i + 1),
        type: "room",
        id: id + "-roo-" + i,
        children: null
      }));
    }
  }

  return { ...reservation, index: rowIndex + 1, id };
}

export const ariAuditReservationInfoColumns = [
  { name: "Conf #", dataField: "confirmationNumber" },
  { name: "Demand Client", dataField: "companyCode" },
  { name: "Property Name", dataField: "propertyName" },
  { name: "Rate Plan", dataField: "rateCode" },
  { name: "CRS Hotel Confirmation #", dataField: "sourceConfirmationNumber" },
  { name: "PMS Conf #", dataField: "pmsConfirmationNumber" },
  { name: "Client Confirmation Number", dataField: "otaConfirmationCode" },
  { name: "Status", dataField: "cancelled", formatter: value => (value ? "Canceled" : "Active") },
  {
    name: "Guest Name",
    dataField: "guestName",
    formatter: (value, row) => `${row.guestFirstName} ${row.guestLastName}`
  },
  {
    name: "No Of Guests",
    dataField: "noOfGuests",
    formatter: (cell, row) =>
      row.noOfAdults + "A" + (row.noOfChildren > 0 ? " / " + row.noOfChildren + "C" : "")
  },
  { name: "Room Code", dataField: "roomCode" },
  { name: "Room Type", dataField: "room" },
  { name: "LOS", dataField: "los" },
  {
    name: "Sale Date",
    dataField: "bookingDateDuplicate",
    formatter: (cell, row) => getFormattedDateInEDTWithFormat(row["bookingDateDuplicate"])
  },
  { name: "Sale Time", dataField: "bookingDateDuplicate", formatter: getFormattedTime },
  { name: "Arrival", dataField: "arrival" },
  { name: "Departure", dataField: "departure" },
  { name: "Lead Time", dataField: "leadTime" },
  { name: "Comments", dataField: "comment" },
  { name: "Special Requests", dataField: "specialRequest" }
];

export const ariAuditPricingInfoColumns = [
  { name: "Payable Cost", dataField: "payableNetPrice" },
  { name: "Payable Tax", dataField: "payableTax" },
  { name: "Payable Total", dataField: "payableGrossPrice" },
  { name: "HT Commission%", dataField: "htCommissionPercent" },
  { name: "Invoice Price", dataField: "receivableNetPrice" },
  { name: "Invoice Tax", dataField: "receivableTax" },
  { name: "Invoice Total", dataField: "receivableGrossPrice" },
  { name: "Client Sale Price", dataField: "clientSalePrice" },
  { name: "Client Taxes", dataField: "taxes" },
  { name: "Client Gross Price", dataField: "grossPrice" },
  { name: "Extra Adult Amount", dataField: "extraAdultAmount" },
  { name: "Max Adult Occupancy", dataField: "maxAdultOccupancy", formatter: cell => cell },
  { name: "Extra Child Amount", dataField: "extraChildAmount" },
  { name: "Max Child Occupancy", dataField: "maxChildOccupancy", formatter: cell => cell }
].map(col => ({
  ...col,
  formatter: col.formatter ? col.formatter : value => getRoundedToTwoDecimals({ value })
}));

export const reservationDetailsFields = {
  reservationPropertyDetailsFieldsLeft: [
    { fieldKey: "name", label: "Name" },
    { fieldKey: "code", label: "Code" },
    { fieldKey: "starRating", label: "Star Rating" },
    { fieldKey: "timezoneId", label: "Timezone" },
    { fieldKey: "city", label: "City" },
    { fieldKey: "zip", label: "Zip" },
    { fieldKey: "checkInHours", label: "Check In Hours" },
    { fieldKey: "checkInMinutes", label: "Check In Minutes" },
    { fieldKey: "minAdultAgeCheckIn", label: "Min Adult Age Check In" },
    {
      fieldKey: "hasAgeBasedPricing",
      label: "Has Age Based Pricing",
      formatter: value => (value === null ? "-" : value ? "Yes" : "No")
    },
    {
      fieldKey: "cancellationGracePeriodApplied",
      label: "CXL Grace Period",
      formatter: value => (value === null ? "-" : value ? "Yes" : "No")
    }
  ],
  reservationPropertyDetailsFieldsRight: [
    // { fieldKey: "crsId", label: "CRS", formatter: value => CRS_DETAILS[value] },
    { fieldKey: "hotelId", label: "Hotel ID" },
    { fieldKey: "phone1", label: "Phone 1" },
    { fieldKey: "primaryEmail", label: "Primary Email" },
    { fieldKey: "address1", label: "Address 1" },
    { fieldKey: "address2", label: "Address 2" },
    { fieldKey: "country", label: "Country" },
    { fieldKey: "state", label: "State" },
    { fieldKey: "checkOutHours", label: "Check Out Hours" },
    { fieldKey: "checkOutMinutes", label: "Check Out Minutes" },
    { fieldKey: "maxChildAge", label: "Max Child Age" }
  ],
  reservationRoomTypeDetailsFieldsLeft: [
    { fieldKey: "displayName", label: "Name" },
    { fieldKey: "maxAdultOccupancy", label: "Max Adult Occupancy" },
    { fieldKey: "totalMaxOccupancy", label: "Total Max Occupancy" }
  ],
  reservationRoomTypeDetailsFieldsRight: [
    { fieldKey: "code", label: "Code" },
    { fieldKey: "maxChildOccupancy", label: "Max Child Occupancy" }
  ],
  reservationClientDetailsFieldsLeft: [
    { fieldKey: "clientName", label: "Client Name" },
    { fieldKey: "clientVersion", label: "Client Version" }
  ],
  reservationRoomTypeDetailsLongFields: [
    { fieldKey: "shortDescription", label: "Short Description" },
    { fieldKey: "longDescription", label: "Long Description" }
  ],
  reservationClientDetailsFieldsRight: [
    {
      fieldKey: "integrationType",
      label: "Integration Type (Connection Type)",
      formatter: type => clientIntegrationTypes.find(iType => iType.type === type)["label"]
    }
  ],
  reservationRateplanDetailsFieldsLeft: [
    { fieldKey: "name", label: "Name" },
    { fieldKey: "code", label: "Code" },
    { fieldKey: "minLeadTime", label: "Min Lead Time" },
    {
      fieldKey: "isRefundable",
      label: "Is Refundable",
      formatter: value => (value ? "Yes" : "No")
    },
    {
      fieldKey: "isLunchIncluded",
      label: "Is Lunch Included",
      formatter: value => (value ? "Yes" : "No")
    },
    {
      fieldKey: "isAllInclusive",
      label: "Is All Inclusive",
      formatter: value => (value ? "Yes" : "No")
    },
    { fieldKey: "mealPlanCode", label: "Mealplan Code(Supply)" }
  ],
  reservationRateplanDetailsFieldsRight: [
    { fieldKey: "internalHtName", label: "internal HT Name" },
    { fieldKey: "isDpr", label: "Is DPR", formatter: value => (value ? "Yes" : "No") },
    { fieldKey: "maxLeadTime", label: "Max Lead Time" },
    {
      fieldKey: "policy",
      label: "Default Cancellation Policy",
      formatter: (value, data) => (data["policy"] ? data["policy"]["name"] : "")
    },
    {
      fieldKey: "isBreakfastIncluded",
      label: "Is Breakfast Included",
      formatter: value => (value ? "Yes" : "No")
    },
    {
      fieldKey: "isDinnerIncluded",
      label: "Is Dinner Included",
      formatter: value => (value ? "Yes" : "No")
    },
    { fieldKey: "mealPlanCodeDemand", label: "Mealplan Code(Demand)" },
    { fieldKey: "mealPlanName", label: "Mealplan Name" }
  ],
  reservationRateplanDetailsLongFields: [
    { fieldKey: "mealPlanDescription", label: "Mealplan Description" }
  ],
  reservationRateplanDetailsTableFields: [
    {
      label: "Policy Overrides",
      data: { getTableData: d => d["smartPolicies"] },
      columns: [
        { name: "#", dataField: "index", width: 120, formatter: (value, row, ri) => ri + 1 },
        { name: "Start Date", dataField: "stayStartDate", width: 120 },
        { name: "End Date", dataField: "stayEndDate", width: 120 },
        {
          name: "Policy Code",
          dataField: "policy",
          width: 120,
          formatter: obj => (obj ? obj["code"] : "-")
        }
      ],
      noDataRenderer: "No Policy Overrides Present."
    }
  ],
  reservationTaxesDetailsTableFields: [
    {
      label: "",
      data: { getTableData: d => d },
      columns: [
        // { name: "#", dataField: "serial", width: 120, formatter: (value, row, ri) => ri + 1 },
        { name: "Tax Name", dataField: "name", width: 120 },
        {
          name: "Pay At Property",
          dataField: "payAtProperty",
          formatter: bool => (bool ? "Yes" : "No"),
          width: 70
        },
        { name: "Charge Type", dataField: "chargeType", width: 70 },
        { name: "Charge Basis", dataField: "chargeBasis", width: 70 },
        { name: "Charge Frequency", dataField: "chargeFrequency", width: 90 },
        { name: "Charge Amount", dataField: "chargeAmount", width: 70 }
        // { name: "Is Active", dataField: "isActive", formatter: bool => (bool ? "Yes" : "No"), width: 70}
      ],
      noDataRenderer: "No Data"
    }
  ]
};

export const hiltonTaxFieldsMapping = {
  chargeTypeMapping: {
    percent: "PERCENT",
    flat: "FLAT"
  },
  chargeBasisMapping: {
    perRoom: "ROOM",
    perPerson: "PERSON"
  },
  chargeFrequencyMapping: {
    perNight: "DAY",
    perStay: "STAY"
  }
};

export const getUpdatedTaxTypesForHilton = taxes => {
  const { chargeTypeMapping, chargeBasisMapping, chargeFrequencyMapping } = hiltonTaxFieldsMapping;
  return taxes.map(tax => {
    const { chargeType, chargeBasis, chargeFrequency } = tax;
    tax["chargeType"] = chargeTypeMapping[chargeType] ?? chargeType;
    tax["chargeBasis"] = chargeBasisMapping[chargeBasis] ?? chargeBasis;
    tax["chargeFrequency"] = chargeFrequencyMapping[chargeFrequency] ?? chargeFrequency;
    return tax;
  });
};

export const VCC_UNMASK_COMMENTS = [
  "VCC charged - additional attempt",
  "VCC incorrect amt /non-tax   issue",
  "VCC Declined (Not Funded yet)",
  "VCC Funding amount declined",
  "VCC Overcharged",
  "VCC FF being charged",
  "VCC Funding Acct Declined",
  "VCC Wiped off",
  "VCC Extend error",
  "VCC Auth/hold",
  "VCC Inactive",
  "VCC Incorrect Tax",
  "VCC Credit/Refund Issue",
  "VCC AMEX Not Accepted"
];
