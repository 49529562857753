import SecureXS from "../secure-xs";
import { USER_ROLES } from "../../constants";

export const USER_PROFILE = "userProfile";
export const JWT_TOKEN = "jwtToken";
const SecureSS = new SecureXS();

export function isASuperUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).role === USER_ROLES.SUPER_USER;
}

export function isAPotentialUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).role === USER_ROLES.POTENTIAL_USER;
}

export function isAClientUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).userType === "CLIENT";
}

export function isClientWithInvoiceAccess() {
  const role = SecureSS.get(USER_PROFILE).role;
  return [
    USER_ROLES.CLIENT_FINANCE,
    USER_ROLES.CLIENT_COMMERCIAL_TEAM,
    USER_ROLES.CLIENT_ADMIN
  ].includes(role);
}

export function isASupplierUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).userType === "SUPPLIER";
}

export function getLoggedUserClientProfile() {
  return SecureSS.get(USER_PROFILE);
}

export function getLoggedUserClientId() {
  return SecureSS.get(USER_PROFILE).clientId;
}

export function isAPullClientLoggedIn() {
  return SecureSS.get(USER_PROFILE).clientType === "CLIENT_PULL";
}

export function isAPushClientLoggedIn() {
  return SecureSS.get(USER_PROFILE).clientType === "CLIENT_PUSH";
}

export function isAPullOrPushClientLoggedIn() {
  // The assumption here is there will be only 2 types of clients
  // CLIENT_PUSH & CLIENT_PULL
  const clientType = SecureSS.get(USER_PROFILE).clientType;
  return clientType === "CLIENT_PUSH" || clientType === "CLIENT_PULL";
}

export function getLoggedUserUsername() {
  return SecureSS.get(USER_PROFILE).username;
}

export function isAClientAdminUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).role === USER_ROLES.CLIENT_ADMIN;
}

export function isAClientFinanceUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).role === USER_ROLES.CLIENT_FINANCE;
}

export function getLoggedUserRole() {
  return SecureSS.get(USER_PROFILE).role;
}

export function isAHotelbedsClientUser() {
  return SecureSS.get(USER_PROFILE).clientCode === "Hotelbeds";
}

export function isReservationAgentUserLoggedIn() {
  return SecureSS.get(USER_PROFILE).role === USER_ROLES.RESERVATION_AGENT
}

export default SecureSS;
