export const DEFAULT_PROPERTY_CODE_1 = "66316";
export const DEFAULT_PROPERTY_CODE_2 = "NYCMTS";

export const prodTestBookingConfigOccupancyMapping = {
  "2_ADULTS": {
    adultCount: 2,
    childCount: 0
  },
  "1_ADULT": {
    adultCount: 1,
    childCount: 0
  },
  "2_ADULTS_1_CHILD": {
    adultCount: 2,
    childCount: 1
  },
  "2_ADULTS_2_CHILDREN": {
    adultCount: 2,
    childCount: 2
  }
};

export const prodTestBookingConfigObject = {
  propertyId: "",
  occupancy: "2_ADULTS",
  startDate: "",
  endDate: ""
};

export const clientDocuments = {
  platformConfigDocument: null,
  customSLADocument: null,
  distributionAgreement: null
};

export const clientDocumentConfig = {
  showPlatformConfig: false,
  overrideCustomSLADocument: false,
  ShowDistributionAgreement: false,
  ...clientDocuments
};

export const addClient = {
  clientName: "",
  clientCode: "",
  shortClientCode: "",
  clientVersion: "",
  integrationType: "CLIENT_PUSH",
  username: "",
  password: "",
  token: null,
  availabilityAndRatesPush: {
    url: "",
    username: "",
    password: "",
    isActive: false
  },
  isActive: false,
  address: {
    address1: "",
    address2: "",
    cityName: "",
    stateName: "",
    zipCode: "",
    countryCode: ""
  },
  allowMispricedReservations: false,
  accountOwnerName: "",
  accountOwnerTitle: "",
  accountOwnerEmail: "",
  accountOwnerPhone: "",
  integrationContacts: [],
  technicalContacts: [],
  escalationContacts: [],
  financeManagementContacts: [],
  operationManagementContacts: [],
  financeSupportContacts: [],
  operationSupportContacts: [],
  operationLocations: [],
  phone: "",
  email: "",
  depositAmount: 0,
  creditLimit: 0,
  contractedDeposit: 0,
  depositReceived: 0,
  depositType: null,
  agreementStartDate: "",
  agreementEndDate: "",
  billingTerms: null,
  clientUsers: [
    {
      firstName: "",
      lastName: "",
      username: "",
      roleId: 8,
      userType: "CLIENT"
    }
  ],
  moUserIds: [],
  prodTestBookingsConfig: [],
  invoicingScheduleDetailsDTO: null,
  clientGroupings: [],
  timezoneMappings: [],
  hiltonEnabled: false,
  hiltonRateAccessCode: "",
  clientDocumentConfig: { ...clientDocumentConfig }
};

export const clientSupportContactObject = {
  clientId: "",
  contactGroup: "OPERATION",
  contactType: "PRE_TRAVEL",
  email: "",
  htClientPortalAccess: false,
  phone: "",
  escalationName: "",
  escalationEmail: "",
  escalationPhone: "",
  notes: ""
};

export const clientManagementContactObject = {
  clientId: "",
  contactGroup: "OPERATION",
  contactType: "",
  name: "",
  title: "",
  email: "",
  phoneOffice: "",
  phoneCell: ""
};

export const clientOperationLocationObject = {
  location: "",
  timezone: "",
  operateAllTime: false
};

export const clientUserObject = {
  firstName: "",
  lastName: "",
  username: "",
  roleId: 5,
  sendNewClientForm: false,
  moUser: false,
  userType: "CLIENT"
};

export const clientIntegrationTypes = [
  {
    type: "CLIENT_PULL",
    label: "Pull Client"
  },
  {
    type: "CLIENT_PUSH",
    label: "Push Client"
  }
];

export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: "Demand",
    value: "DEMAND"
  },
  {
    label: "Platform",
    value: "PLATFORM"
  },
  {
    label: "Internal",
    value: "INTERNAL"
  },
  {
    label: "3rd party",
    value: "THIRD_PARTY"
  }
];

export const PLATFORM_OPTIONS = [
  {
    label: "Direct",
    value: "DIRECT"
  },
  {
    label: "TravelgateX",
    value: "TRAVELGATEX"
  },
  {
    label: "Juniper",
    value: "JUNIPER"
  },
  {
    label: "Anixe",
    value: "ANIXE"
  },
  {
    label: "Koedia",
    value: "KOEDIA"
  },
  {
    label: "eMinds",
    value: "EMINDS"
  },
  {
    label: "Netstorming",
    value: "NETSTORMING"
  },
  {
    label: "Gimmonix",
    value: "GIMMONIX"
  },
  {
    label: "RateDock",
    value: "RATEDOCK"
  },
  {
    label: "Intuitive",
    value: "INTUITIVE"
  },
  {
    label: "Zentrumhub",
    value: "ZENTRUMHUB"
  },
  {
    label: "Travel Compositor",
    value: "TRAVEL_COMPOSITOR"
  }
];

export const GROUPED_MULTI_OPTION = "multi_select";
export const SINGLE_CHOICE_MULTI_OPTION = "single_select";
export const SINGLE_CHOICE_BINARY_OPTION = "binary";
export const QUES_TYPE_SELECT = "drop_down";
export const QUES_TYPE_NUMBER = "number";
export const QUES_TYPE_NUMBER_ARRAY = "number_array";
export const QUES_TYPE_STRING = "string";
export const QUES_TYPE_STRING_ARRAY = "string_array";

export const getTrimmedValue = val => {
  return val ? val.replaceAll(/\s/g, "") : "";
};

export const QUESTIONNAIRES = [
  "API Client Questionnaire",
  "Sandbox Questionnaire",
  "Client Details Form",
  "Prod Test Questionnaire"
];

export const QUESTIONNAIRE_TYPES = {
  [QUESTIONNAIRES[0]]: "client_api",
  [QUESTIONNAIRES[1]]: "sandbox",
  [QUESTIONNAIRES[2]]: "client_account_details",
  [QUESTIONNAIRES[3]]: "prod"
};

export const CLIENT_QUESTIONNAIRE_STAGES = [
  "New",
  "API client questionnaire",
  "Sandbox testing",
  "Production testing",
  "Pre-launch",
  "Live"
];

export const CLIENT_STAGES_QUESTIONNAIRES_MAPPING = {
  [CLIENT_QUESTIONNAIRE_STAGES[1]]: QUESTIONNAIRES[0],
  [CLIENT_QUESTIONNAIRE_STAGES[2]]: QUESTIONNAIRES[1],
  [CLIENT_QUESTIONNAIRE_STAGES[3]]: QUESTIONNAIRES[3],
  [CLIENT_QUESTIONNAIRE_STAGES[4]]: QUESTIONNAIRES[3]
};

export const ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS = [
  "OB_WELCOME",
  "OB_API_QUESTIONNAIRE_LAUNCH",
  "API_QUESTIONNAIRE",
  "API_QUESTIONNAIRE_UNDER_REVIEW",
  "OB_SANDBOX_QUESTIONNAIRE_LAUNCH",
  "SANDBOX_QUESTIONNAIRE",
  "OB_SANDBOX_CERTIFIED",
  "OB_PLATFORM_ACTIVATION_PENDING",
  "OB_PLATFORM_ACTIVATED",
  "OB_PROD_TESTING_LAUNCH",
  "PROD_TESTING_QUESTIONNAIRE",
  "PROD_TESTING_QUESTIONNAIRE_UNDER_REVIEW",
  "CERTIFICATION_COMPLETE"
];

const CLIENT_STEP_STAGE_MAPPING = {
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[0]]: CLIENT_QUESTIONNAIRE_STAGES[0],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[1]]: CLIENT_QUESTIONNAIRE_STAGES[0],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2]]: CLIENT_QUESTIONNAIRE_STAGES[1],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]]: CLIENT_QUESTIONNAIRE_STAGES[1],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4]]: CLIENT_QUESTIONNAIRE_STAGES[1],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5]]: CLIENT_QUESTIONNAIRE_STAGES[2],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[6]]: CLIENT_QUESTIONNAIRE_STAGES[2],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[7]]: CLIENT_QUESTIONNAIRE_STAGES[1],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[8]]: CLIENT_QUESTIONNAIRE_STAGES[1],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10]]: CLIENT_QUESTIONNAIRE_STAGES[3],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11]]: CLIENT_QUESTIONNAIRE_STAGES[4],
  [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12]]: CLIENT_QUESTIONNAIRE_STAGES[5]
};

export const getClientStage = (clientStep, clientPlatform) => {
  if (clientStep === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[9]) {
    if (clientPlatform !== PLATFORM_OPTIONS[0]?.value) {
      return CLIENT_QUESTIONNAIRE_STAGES[1];
    }
    return CLIENT_QUESTIONNAIRE_STAGES[2];
  }
  return clientStep ? CLIENT_STEP_STAGE_MAPPING[clientStep] : "";
};

export const CLIENT_PENDING_HT_REVIEW = "Pending_HT_Review";
export const HILTON_APPROVED_GROUP_NAME = "Hilton Approved";
export const HILTON_APPROVED_YES = "Yes";
export const TESTING_FX_GROUP_NAME = "Testing FX";
