import React, { Suspense, useEffect } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";

import "react-texty/styles.css";
import "react-base-table/styles.css";

import rootReducer from "./reducers";
import { logPageView } from "./utils/AnalyticsTracker";
import Loader from "./components/Home/Loader";
const Home = React.lazy(() => import("./components/Home" /* webpackChunkName: "Home" */));
const Login = React.lazy(() => import("./components/Login" /* webpackChunkName: "Login"*/));
const CRSReservation = React.lazy(() =>
  import("./components/CRSReservation" /* webpackChunkName: "CRSReservation"*/)
);
const MMMSummaryScreen = React.lazy(() =>
  import("./components/MMMSummaryScreen" /* webpackChunkName: "MMMSummaryScreen"*/)
);
const Auth = React.lazy(() => import("./components/Auth" /* webpackChunkName: "Auth"*/));
const VccSecure = React.lazy(() =>
  import("./components/VccSecure/VccSecure" /* webpackChunkName: "VccSecure"*/)
);

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__?.());

const App = () => (
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <Auth>
        <Router>
          <RouterChildrenSubComponent />
        </Router>
      </Auth>
    </Provider>
  </Suspense>
);

function RouterChildrenSubComponent() {
  const history = useHistory();
  useEffect(() => {
    logPageView(history);
  }, [history]);

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/crs-res" component={CRSReservation} />
      <Route path="/mmm-summary" component={MMMSummaryScreen} />
      <Route path="/home" component={Home} />
      <Route path="/secure-vcc/:secureString" component={VccSecure} />
      <Route
        path="/resend-secure-vcc/:secureString"
        render={props => <VccSecure isResend={true} {...props} />}
      />
    </Switch>
  );
}
export default App;
