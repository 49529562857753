let API_SERVER_PREFIX = process.env.REACT_APP_SERVER_DOMAIN;
if (process.env.NODE_ENV !== "development") {
  if (process.env.REACT_APP_SERVER_ENVIRONMENT === "PROD") {
    API_SERVER_PREFIX = process.env.REACT_APP_SERVER_DOMAIN;
  } else {
    API_SERVER_PREFIX =
      process.env.REACT_APP_SERVER_DOMAIN + process.env.REACT_APP_SERVER_ENVIRONMENT + "/";
  }
}
export const ADMIN_APP_API_PREFIX = API_SERVER_PREFIX + "1.0/";
