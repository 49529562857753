import { INITIAL_INVOICE_SELECTED_CLIENT_N_FILTER } from "../constants/invoices";

const initialState = {
  clients: [],
  selectedClientAndFilter: INITIAL_INVOICE_SELECTED_CLIENT_N_FILTER
};

export default function onboarding(state = initialState, params = {}) {
  const { payload } = params;
  return { ...state, ...payload };
  // }
}
