import { getReservation, cancelReservation } from "../api/index";
import { toast } from "react-toastify";

export const SET_CANCEL_RESERVATION_LOADING = "SET_CANCEL_RESERVATION_LOADING";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_GET_DETAILS = "SET_GET_DETAILS";
export const SET_RESERVATION_DETAILS = "SET_RESERVATION_DETAILS";

//action creators
export const setCancelReservationLoading = bool => ({
  type: SET_CANCEL_RESERVATION_LOADING,
  cancelReservationLoading: bool
});
export const setIsLoading = bool => ({
  type: SET_IS_LOADING,
  isLoading: bool
});
export const setGetDetails = bool => ({
  type: SET_GET_DETAILS,
  getDetails: bool
});
export const setReservationDetails = reservationDetails => ({
  type: SET_RESERVATION_DETAILS,
  reservationDetails
});

//async actions
export const asyncGetReservationDetails = (params, dispatch) => {
  dispatch(setIsLoading(true));
  dispatch(setGetDetails(true));
  return getReservation(params).then(
    success => {
      dispatch(setIsLoading(false));
      if (success.body) {
        dispatch(setReservationDetails(success.body.reservationReportDetails));
      }
    },
    failure => {
      dispatch(setIsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncCancelReservation = (htConfCode, dispatch) => {
  dispatch(setCancelReservationLoading(true));
  return cancelReservation(htConfCode).then(
    success => {
      dispatch(setCancelReservationLoading(false));
      if (success.status === 200) {
        toast.success("Reservation cancelled!");
      }
    },
    failure => {
      dispatch(setCancelReservationLoading(false));
      console.log(failure);
    }
  );
};
